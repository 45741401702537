import React from "react"
import SizedContainer from "../adobe/SizedContainer"
import FullWindow from "../adobe/FullWindow"

const PDFViewer = () => {
  return (
    <>
      {/* <SizedContainer /> */}
      <FullWindow />
    </>
  )
}

export default PDFViewer
